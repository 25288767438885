import { convertToNumber } from '@/utils/common';
import { getCurrentDate, getSubtractDate } from '@/utils/date';

export class SearchFeatureFlagDto {
  constructor(data) {
    this.name = data.name || null;
    this.fromDate = data.fromDate || getSubtractDate(30);
    this.toDate = data.toDate || getCurrentDate();
    this.storeIds = data.storeIds || null;
    this.userIds = data.userIds || null;
    this.jobTitleIds = data.jobTitleIds || null;
    this.stage = data.stage || null;
    this.page = convertToNumber({
      value: data.page,
      defaultValue: 1,
    });
    this.size = convertToNumber({
      value: data.size,
      defaultValue: 10,
    });
  }
}
