<template>
  <div>
    {{ id }}
    <KTCodePreview :title="titlePage">
      <template v-slot:toolbar>
        <b-dropdown @click="showModal" size="sm" right split variant="primary">
          <template v-slot:button-content>
            <div class="font-weight-bolder">
              <span>
                <i class="flaticon2-add-1 icon-font-size"></i>Tạo mới</span
              >
            </div>
          </template>
        </b-dropdown>
      </template>
      <template v-slot:preview>
        <b-row class="mb-5">
          <b-col>
            <div class="d-flex">
              <date-picker
                placeholder="Từ"
                class="form-control-sm input-style mr-2"
                :config="dpConfig"
                v-model="apiParams.fromDate"
              ></date-picker>
              <date-picker
                placeholder="Đến"
                class="form-control-sm input-style"
                :config="dpConfig"
                v-model="apiParams.toDate"
              ></date-picker>
            </div>
          </b-col>
          <b-col>
            <b-form-group>
              <b-input
                size="sm"
                placeholder="Nhập ký hiệu tính năng"
                v-model.trim="apiParams.name"
                v-on:keyup.enter="onFilter()"
              ></b-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-select
              class="select-style"
              v-model="apiParams.stage"
              :options="STAGE_OPTIONS"
              size="sm"
            ></b-form-select>
          </b-col>
          <b-col>
            <b-col>
              <BDropdownCustom
                v-model="apiParams.storeIds"
                :options="storeOptions"
                :placeholder="'cửa hàng'"
                :searchable="true"
                :matchKey="['name', 'shortName']"
                :limit="5"
              />
            </b-col>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col>
            <b-button
              style="font-weight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="onFilter"
              >Lọc
            </b-button>
          </b-col>
        </b-row>

        <b-table :items="items" :fields="fields" bordered hover>
          <template #cell(status)="row">
            <b-badge :variant="row.item.status ? `success` : `danger`"
              >{{ row.item.status ? 'Đang hoạt động' : 'Đã khóa' }}
            </b-badge>
          </template>
          <template #cell(stage)="row">
            <b-badge :variant="getStageBadgeSettings(row.item.stage).variant"
              >{{ getStageBadgeSettings(row.item.stage).label }}
            </b-badge>
          </template>
          <template #cell(details)="row">
            storeIds:
            <span v-html="renderStrings(row.item.details.storeIds)" />
            <hr />
            employeeIds:
            <span v-html="renderStrings(row.item.details.employeeIds)" />
            <hr />
            jobTitleIds:
            <span v-html="renderStrings(row.item.details.jobTitleIds)" />
          </template>
          <template #cell(actions)="row">
            <b-dropdown size="sm" id="dropdown-left" no-caret right>
              <template slot="button-content">
                <i class="flaticon2-settings function-btn"></i>
              </template>
              <b-dropdown-item @click="showModal(row.item.id)">
                <span style="color: #3f4254; font-size: 12px">
                  <i style="font-size: 1rem" class="flaticon2-pen"></i>
                  &nbsp; Chỉnh sửa
                </span>
              </b-dropdown-item>
              <b-dropdown-item @click="showDeleteAlert(row.item.id)">
                <span style="color: #3f4254; font-size: 12px">
                  <i
                    style="font-size: 1rem; color: #d33"
                    class="flaticon2-rubbish-bin-delete-button"
                  ></i>
                  &nbsp; Xóa
                </span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <!-- Paginate session -->
        <b-row>
          <b-col>
            <p class="mt-3 text-dark font-weight-bold">
              Tổng:
              {{ totalItems }}
            </p>
          </b-col>
          <b-col>
            <BPaginationNavCustom
              :number-of-pages="totalPages || 1"
              :current-page="apiParams.page"
              :total-page="totalPages"
              :linkParams="{}"
              @page-change="fetchData()"
            >
            </BPaginationNavCustom>
          </b-col>
        </b-row>
        <!-- End of Paginate session -->
      </template>
    </KTCodePreview>
    <FeatureFlagModal :id="id" v-on:closeModal="handleCloseModal" />
  </div>
</template>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import { cmdUrl } from '@/utils/apiUrl';
import { SearchFeatureFlagDto } from '@/view/pages/feature-flags/models/SearchFeatureFlagDto';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { DP_CONFIG } from '@/utils/date';
import {
  STAGE_OPTIONS,
  TABLE_FIELDS,
} from '@/view/pages/feature-flags/utils/constants';
import BDropdownCustom from '@/view/base/bootstrap/BDropdownCustom.vue';
import FeatureFlagModal from '@/view/pages/feature-flags/FeatureFlagModal.vue';
import Swal from 'sweetalert2';
import BPaginationNavCustom from '@/view/base/bootstrap/BPaginationNavCustom.vue';
import { handleError } from '@/utils/common';

export default {
  components: {
    FeatureFlagModal,
    BDropdownCustom,
    KTCodePreview,
    BPaginationNavCustom,
  },
  data() {
    return {
      id: null,
      titlePage: 'Danh sách các tính năng đang triển khai',
      dpConfig: DP_CONFIG.date,
      STAGE_OPTIONS: STAGE_OPTIONS,

      items: [],
      fields: TABLE_FIELDS,
      apiParams: new SearchFeatureFlagDto({}),
      storeOptions: [],
      jobTitleOptions: [],
      userOptions: [],
      totalPages: 1,
      totalItems: 0,
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
    };
  },
  methods: {
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'feature-flag-list',
      });
      this.fetchData();
    },
    showModal(featureFlagId) {
      this.$bvModal.show('modal-1');
      if (isNaN(Number(featureFlagId))) {
        this.id = null;
        return;
      }
      this.id = featureFlagId;
    },
    handleCloseModal() {
      this.id = null;
      this.fetchData();
    },
    renderStrings(text) {
      return text ? text : 'Chưa thiết đặt';
    },
    fetchStores: function () {
      ApiService.query('stores/getStores').then((response) => {
        if (response.data.status) {
          this.storeOptions = response.data.data;
        }
      });
    },
    fetchJobTitles: function () {
      ApiService.query('job-title-config/job-titles').then((response) => {
        if (response.data.status) {
          this.jobTitleOptions = response.data.data;
        }
      });
    },
    fetchData: function () {
      this.apiParams.page = this.$route.query.page || 1;
      ApiService.query(cmdUrl.FeatureFlag.root, {
        params: this.apiParams,
      })
        .then((response) => {
          if (response.data.status) {
            const { result, totalItems, totalPages } = response.data.data;
            this.items = result;
            this.totalItems = totalItems;
            this.totalPages = totalPages;
          }
        })
        .catch((error) => handleError(error));
    },
    handleDelete: function (id) {
      const featureFlagResource = cmdUrl.FeatureFlag.byId.replace('{id}', id);
      ApiService.delete(featureFlagResource)
        .then(() => {
          this.fetchData();
        })
        .catch((error) => console.log(error));
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa đánh cờ tính năng!',
        text: 'Bạn có chắc muốn xóa cờ của tính năng này? Cần phải xóa ở code trước. Việc xóa không đảm bảo là process cũ sẽ hoạt động bình thường!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then((result) => {
        if (result.value) {
          this.handleDelete(item);
        }
      });
    },
    getStageBadgeSettings(stage) {
      const badgeSettings = {
        variant: '',
        label: '',
      };
      switch (stage) {
        case 'dev':
          badgeSettings.variant = 'secondary';
          badgeSettings.label = 'Development';
          break;
        case 'sta':
          badgeSettings.variant = 'warning';
          badgeSettings.label = 'Staging';
          break;
        case 'prod':
          badgeSettings.variant = 'danger';
          badgeSettings.label = 'Production';
          break;
        default:
          badgeSettings.variant = 'primary';
          badgeSettings.label = 'Không tồn tại';
      }
      return badgeSettings;
    },
  },
  created() {
    this.fetchStores();
    this.fetchJobTitles();
    this.fetchData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.titlePage, route: '/feature-flags' },
    ]);
  },
};
</script>
<style scoped></style>
