<template>
  <div>
    <b-modal
      id="modal-1"
      :title="modalTitle"
      ok-title="Lưu"
      cancel-title="Hủy"
      @ok="onSubmit"
      :ok-disabled="isLoading"
      @close="reset"
      @hide="reset"
    >
      <h5 class="mb-5">Thông tin chung</h5>
      <b-form-group>
        <label id="input-name"
          >Tên tính năng: <b class="text-danger">*</b></label
        >
        <b-form-input
          size="sm"
          id="input-name"
          v-model.trim="data.name"
          placeholder="Nhập tên tính năng"
          trim
          :formatter="formatLowercase"
        />
      </b-form-group>
      <b-form-group>
        <label id="input-stage">Môi trường: <b class="text-danger">*</b></label>
        <b-form-select :options="stageOptions" v-model="data.stage" />
      </b-form-group>
      <b-form-group>
        <label>Trạng thái: <b class="text-danger">*</b></label>
        <b-form-checkbox
          size="md"
          v-model="data.status"
          name="check-button"
          switch
        >
          Đang hoạt động
        </b-form-checkbox>
      </b-form-group>

      <h5 class="mt-5">Thông tin cấu hình</h5>
      <h6 class="mb-5 text-info font-weight-lighter">
        Khi không chọn sẽ cho phép tất cả
      </h6>
      <b-form-group>
        <label id="input-storeIds">Cửa hàng áp dụng:</label>
        <Treeselect
          :options="storeOptions"
          :multiple="true"
          placeholder="Chọn cửa hạng áp dụng"
          noResultsText="Không có kết quả"
          noOptionsText="Không lấy được các lựa chọn cửa hàng"
          :match-keys="['name', 'shortName']"
          openDirection="bottom"
          :clearable="true"
          v-model="data.details.storeIds"
        ></Treeselect>
      </b-form-group>
      <b-form-group>
        <label id="input-jobTitleIds">Chức vụ áp dụng:</label>
        <Treeselect
          :options="jobTitleOptions"
          :multiple="true"
          placeholder="Chọn chức vụ áp dụng"
          noResultsText="Không có kết quả"
          noOptionsText="Không lấy được các lựa chọn chức vụ"
          :match-keys="['name']"
          openDirection="bottom"
          :clearable="true"
          v-model="data.details.jobTitleIds"
        ></Treeselect>
      </b-form-group>
      <b-form-group>
        <label id="input-employeeIds">Nhân viên áp dụng:</label>
        <Treeselect
          :load-options="loadEmployeeOptions"
          :options="employeeOptions"
          :async="true"
          :multiple="true"
          placeholder="Chọn nhân viên áp dụng"
          noResultsText="Không có kết quả"
          noOptionsText="Không lấy được các lựa chọn nhân viên"
          :match-keys="['name']"
          openDirection="bottom"
          :clearable="true"
          v-model="data.details.employeeIds"
          :cache-options="true"
        ></Treeselect>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import { CreateFeatureFlagDto } from '@/view/pages/feature-flags/models/CreateFeatureFlagDto';
import Treeselect, {
  ASYNC_SEARCH,
  LOAD_ROOT_OPTIONS,
} from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { STAGE_OPTIONS } from '@/view/pages/feature-flags/utils/constants';
import { cmdUrl } from '@/utils/apiUrl';
import ApiService from '@/core/services/api.service';
import { StoreOption } from '@/view/pages/feature-flags/models/StoreOption';
import { JobTitleOption } from '@/view/pages/feature-flags/models/JobTitleOption';
import { EmployeeOption } from '@/view/pages/feature-flags/models/EmployeeOption';
import { makeToastFaile, makeToastSuccess } from '@/utils/common';
import { showError } from '@/view/pages/feature-flags/utils/common';
import { UpdateFeatureFlagDto } from '@/view/pages/feature-flags/models/UpdateFeatureFlagDto';

export default {
  props: ['id'],
  components: { Treeselect },
  data() {
    return {
      isLoading: false,
      data: new CreateFeatureFlagDto({ details: { employeeIds: [] } }),
      stageOptions: STAGE_OPTIONS,
      storeOptions: [],
      jobTitleOptions: [],
      employeeOptions: [],
      searchEmployee: '',
    };
  },
  watch: {
    id: {
      handler: async function (value) {
        if (value) {
          await this.fetchStores();
          await this.fetchJobTitles();
          await this.fetchData();
        } else {
          this.reset();
        }
      },
    },
  },
  methods: {
    fetchData() {
      const featureFlagResource = cmdUrl.FeatureFlag.byId.replace(
        '{id}',
        this.id,
      );
      ApiService.get(featureFlagResource)
        .then(async (response) => {
          this.data = Object.assign(
            {},
            new UpdateFeatureFlagDto(response.data.data),
          );
          await this.loadEmployeeOptions({ action: LOAD_ROOT_OPTIONS });
        })
        .catch(showError);
    },
    fetchStores() {
      const params = {};
      ApiService.query(cmdUrl.Stores.root, params)
        .then((response) => {
          if (response.status) {
            this.storeOptions = response.data.data.map(
              (store) => new StoreOption(store),
            );
          }
        })
        .catch((error) => {
          if (error) {
            this.storeOptions = [];
          }
        });
    },
    fetchJobTitles() {
      ApiService.query('job-title-config/job-titles')
        .then((response) => {
          if (response.data.status) {
            this.jobTitleOptions = response.data.data.map(
              (jobTitle) => new JobTitleOption(jobTitle),
            );
          }
        })
        .catch((error) => {
          if (error) {
            this.jobTitleOptions = [];
          }
        });
    },
    fetchEmployees(searchQuery) {
      this.onLoading = true;
      const params = {
        page: 1,
        pageSize: 10,
        fullName: searchQuery,
      };
      const paramQuery = { params };
      return ApiService.query('employees', paramQuery)
        .then((response) => {
          if (response.status) {
            this.employeeOptions = response.data.data.employees.map(
              (employee) => new EmployeeOption(employee),
            );
            return this.employeeOptions;
          }
        })
        .catch((error) => {
          if (error) {
            this.employeeOptions = [];
          }
        });
    },
    fetchEmployeesById(ids) {
      if (!ids || ids.length === 0) return [];
      return ApiService.query(`${cmdUrl.EmployeeUrl.root}/get-by-query`, {
        params: { employeeIds: ids },
      })
        .then((response) => {
          const result = response.data.data.map(
            (employee) => new EmployeeOption(employee),
          );
          this.employeeOptions = result;
          return result;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async loadEmployeeOptions({ action, searchQuery, callback }) {
      if (action === ASYNC_SEARCH) {
        const result = await this.fetchEmployees(searchQuery);
        callback(null, result);
      }

      if (action === LOAD_ROOT_OPTIONS) {
        if (this.data && this.id) {
          const result = await this.fetchEmployeesById(
            this.data.details.employeeIds,
          );
          this.employeeOptions = result;
        }
      }
    },
    formatLowercase(value) {
      return value.toLowerCase();
    },
    reset() {
      this.jobTitleOptions = [];
      this.storeOptions = [];
      this.employeeOptions = [];
      this.$emit('closeModal');
      this.data = Object.assign(
        {},
        new CreateFeatureFlagDto({ details: { employeeIds: [] } }),
      );
    },
    onSubmit(bvModelEvent) {
      this.isLoading = true;
      bvModelEvent.preventDefault();
      if (!this.id) {
        ApiService.post(cmdUrl.FeatureFlag.root, this.data)
          .then((response) => {
            if (response.status) {
              makeToastSuccess('Tạo tính năng thành công');
              this.$bvModal.hide('modal-1');
              this.isLoading = false;
              this.reset();
            }
          })
          .catch((error) => {
            if (error) {
              makeToastFaile('Có lỗi xảy ra');
              this.isLoading = false;
            }
          });
      } else {
        const featureFlagResource = cmdUrl.FeatureFlag.byId.replace(
          '{id}',
          this.id,
        );
        ApiService.put(featureFlagResource, this.data)
          .then((response) => {
            if (response.status) {
              makeToastSuccess('Cập nhật tính năng thành công');
              this.$bvModal.hide('modal-1');
              this.isLoading = false;
              this.reset();
            }
          })
          .catch((error) => {
            if (error) {
              makeToastFaile('Có lỗi xảy ra');
              this.isLoading = false;
            }
          });
      }
    },
  },

  computed: {
    modalTitle() {
      return this.id ? 'Chỉnh sửa tính năng' : 'Thêm mới tính năng';
    },
  },
  mounted() {
    this.fetchStores();
    this.fetchJobTitles();
    if (!this.id) {
      this.fetchEmployees('');
    }
  },
};
</script>
